.ItemHomeBox {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;

  &:hover {
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.1) -4px 9px 25px -6px;
  }
}
