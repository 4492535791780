$primary: #e33942;
$bg-color: #f5f4f3;

// export for bgStyle in component
:export {
  primary: $primary;
  bg-color: $bg-color;
}

$theme-colors: (
  'primary': $primary,
  'warning': $primary,
) !default;
// scss-docs-end theme-colors-map

:root {
  --primary: #e33942 !important;
  --primary-darken-1: hsl(356.82, 75%, 54%);
  --primary-darken-2: hsl(356.82, 75%, 51%);
  --primary-darken-3: hsl(356.82, 75%, 46%);
  --primary-lighten-1: hsl(356.82, 75%, 66%);
  --primary-opacity-0: rgba(227, 57, 66, 0);
  --primary-opacity-0_05: rgba(227, 57, 66, 0.05);
  --primary-opacity-0_1: rgba(227, 57, 66, 0.1);
  --primary-opacity-0_15: rgba(227, 57, 66, 0.15);
  --primary-opacity-0_2: rgba(227, 57, 66, 0.2);
  --primary-opacity-0_25: rgba(227, 57, 66, 0.25);
  --primary-opacity-0_3: rgba(227, 57, 66, 0.3);
  --primary-opacity-0_35: rgba(227, 57, 66, 0.35);
  --primary-opacity-0_4: rgba(227, 57, 66, 0.4);
  --primary-opacity-0_45: rgba(227, 57, 66, 0.45);
  --primary-opacity-0_5: rgba(227, 57, 66, 0.5);
  --primary-opacity-0_55: rgba(227, 57, 66, 0.55);
  --primary-opacity-0_6: rgba(227, 57, 66, 0.6);
  --primary-opacity-0_65: rgba(227, 57, 66, 0.65);
  --primary-opacity-0_7: rgba(227, 57, 66, 0.7);
  --primary-opacity-0_75: rgba(227, 57, 66, 0.75);
  --primary-opacity-0_8: rgba(227, 57, 66, 0.8);
  --primary-opacity-0_85: rgba(227, 57, 66, 0.85);
  --primary-opacity-0_9: rgba(227, 57, 66, 0.9);
  --primary-opacity-0_95: rgba(227, 57, 66, 0.95);
  --theme-color-main: hsl(356.82, 20%, 20%);
  --theme-color-secondary: hsl(356.82, 20%, 80%);
  --theme-color-title: hsl(356.82, 20%, 20%);
  --theme-color-strict-inverse: #ffffff;

  --theme-color-main: #333333 !important;
  --theme-color-title: #333333 !important;

  --theme-color-b24button: var(--primary);
  --navbar-color: #ffffff;
  --navbar-color-hover: hsla(0, 0%, 83%, 1);

  // toastify
  --toastify-color-progress-light: linear-gradient(to right, rgba(227, 57, 66, 0.6), #e33942);
}

@import '~bootstrap/scss/bootstrap';
