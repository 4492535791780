.btn-primary {
  text-transform: uppercase;
  color: #fff;
}

.btn-secondary {
  text-transform: uppercase;
  border: 1px solid $primary;
  color: $primary;
  background-color: transparent;
}

.btn-lg {
  padding: 15px;
}

a {
  text-decoration: none;
}
