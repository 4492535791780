@import 'bootstrap/dist/css/bootstrap.min.css';
@import 'variables';
@import 'custom-bootstrap';
@import 'old'; // old styles for header / footer

// New styles
.app {
  &__headings {
    &__main {
      text-transform: uppercase;
      color: $primary;
      font-weight: 700;
    }

    &__column {
      font-size: 1.25rem;
      text-transform: uppercase;
      color: $primary;
      font-weight: 700;
    }
  }

  &__spinner {
    display: flex;
    margin: 50px auto 100vh;
    width: 5rem;
    height: 5rem;
  }

  &__list {
    list-style: none;

    &--centered {
      padding-left: 50px;

      @include media-breakpoint-between(xs, md) {
        padding-left: 0;
      }
    }

    li:before {
      color: $primary;
      font-weight: 900;
      content: '✓';
      margin-right: 5px;
    }
  }

  &__model-description-wrapper {
    button {
      display: none;
    }
  }

  &__mobile-navbar {
    .landing-block {
      &.g-pr-80 {
        @include media-breakpoint-between(xs, sm) {
          padding: 20px 5px !important;
        }
      }
    }
  }
}

// bitrix styles
.b24-form-wrapper.b24-form-border-bottom {
  border-color: $primary !important;
}
.b24-form-btn {
  background: $primary !important;
}

// modal left

$width_modal: calc(70vw);
$width_mobile: calc(100vw);

.modal.left .modal-dialog {
  position: fixed;
  right: 0;
  margin: auto;
  width: $width_modal;
  height: 100%;
  -webkit-transform: translate3d(0%, 0, 0);
  -ms-transform: translate3d(0%, 0, 0);
  -o-transform: translate3d(0%, 0, 0);
  transform: translate3d(0%, 0, 0);

  @include media-breakpoint-between(xs, sm) {
    width: $width_mobile;
  }
}

.modal.left .modal-content {
  height: 100%;
  overflow-y: auto;
}

.modal.right .modal-body {
  padding: 15px 15px 80px;
}

.modal.right.fade .modal-dialog {
  left: -$width_modal;

  @include media-breakpoint-between(xs, sm) {
    width: -$width_mobile;
  }

  -webkit-transition: opacity 0.3s linear, left 0.3s ease-out;
  -moz-transition: opacity 0.3s linear, left 0.3s ease-out;
  -o-transition: opacity 0.3s linear, left 0.3s ease-out;
  transition: opacity 0.3s linear, left 0.3s ease-out;
}

.modal.right.fade.show .modal-dialog {
  right: 0;
}

/* ----- MODAL STYLE ----- */
.modal-content {
  border-radius: 0;
  border: none;
}

.modal-header {
  border-bottom-color: #eeeeee;
  background-color: #fafafa;
}

.landing-block-node-top-block {
  @include media-breakpoint-between(xs, lg) {
    display: none;
  }
}
