.form-control {
  padding-right: 34px;
}

.formWrapper {
  :global(.rbt-aux .btn-close) {
    background: transparent;
    margin-right: 50px;
    height: auto;
    z-index: 1000;
  }

  :global(.form-label) {
    text-transform: uppercase;
    font-weight: 700;
  }

  :global(.rbt-token-removeable) {
    max-width: 230px;
  }

  :global(.rbt-menu) {
    z-index: 1001;
  }
}
