@import 'styles/variables.scss';
@import url('https://fonts.googleapis.com/css2?family=Courgette&display=swap');

.Home {
  padding: 30px 0;
}
.Motto {
  text-align: center;
  margin: 30px 0;
  font-size: 3.2rem;
  color: $primary;
  font-family: 'Courgette', cursive;
}
